import { graphql, Link } from 'gatsby'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import {
  Height,
  Weight,
  Color,
  Diameter,
  ArrowLeft,
  ArrowRight,
  Cross,
  FillingCAP,
  Check,
} from '../components/icons'
import {
  Button,
  ButtonIcon,
  ButtonSend,
  ButtonToggle,
  HorizontalSlider,
  Layout,
  TextField,
  ContactForm,
} from '../components'
import { black, grayOnBlack, grayOnWhite, white } from '../styling/colors'
import { When } from '../styling/when'
import { useMediaMatch } from '../util'
import { motion } from 'framer-motion'
import { useQueryParam, NumberParam } from 'use-query-params'
import { navigate } from '@reach/router'
import loadingGif from '../assets/loading.gif'

const CenterEvrytingHorizontally = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;

  ${When.Large} {
    align-items: center;
  }
`

const Container = styled.div`
  display: flex;
  margin: 25px 0 0 0;
  padding-bottom: 0;
  width: 100%;

  ${When.Large} {
    margin: 88px 0 0 0;
  }
`

const ContainerOfTheThree = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${When.Large} {
    flex-direction: row;
  }
`

const Name = styled.h1`
  display: none;
  /* margin-top: 80px; */
  margin: 0 0 18px 0;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;

  ${When.Large} {
    display: block;
  }
`

const NameMobile = styled.h1`
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;

  margin: 10px 0;

  ${When.Large} {
    display: none;
  }
`

const SpecsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 9px;
  /* used for mobile for collapse */
  margin-bottom: 13px;

  ${When.Large} {
    width: 400px;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 0;
  }
`

const SpecsTable = styled(motion.div)`
  display: grid;
  grid-template-columns: 37px 150px auto;
  grid-row-gap: 14px;

  ${When.Large} {
    margin-left: 4px;
  }
`

const SpecsCellIcon = styled.div`
  /* margin-right: 17px; */
`
const SpecsCell = styled.div`
  font-weight: 300;
  /* margin-right: 20px; */
`

const SpecsValue = styled.div`
  text-align: right;
`

const SpecValueText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
`

const ContactAndDownloadContainer = styled.div`
  margin-top: 37px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${When.Large} {
    display: flex;
  }
`

const ContactUsButton = styled(Button)`
  margin-bottom: 17px;
`

const DownloadLink = styled.a`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  &::hover {
    cursor: pointer;
  }
`

const BottleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: center;
  position: relative;
  width: 300px;
  margin: 0 auto 13px auto;

  ${When.Large} {
    margin-bottom: 33px;
    /* width: auto; */
  }
`

const StyledHorizontalSider = styled(HorizontalSlider)`
  height: ${({ height }) => (height ? height : '400px')};
  transition: height 0.2s linear;

  @media screen and (max-height: 600px) {
    height: 270px;
  }

  ${When.Large} {
    margin-bottom: 32px;
    width: 300px;
  }

  ${When.LargeAndTall} {
    /* height: 500px; */
  }
`

const StyledHorizontalSider2 = styled(HorizontalSlider)`
  width: min(600px, 90vw);
  height: 70vh;
  transition: height 0.2s linear;

  ${When.Large} {
    margin-bottom: 32px;
  }
`

const MainImage = styled(Img)`
  height: ${({ height }) => (height ? height : '400px')};
  transition: height 0.2s linear;

  background: #fff;
  margin: 0 0px;

  & img {
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-height: 600px) {
    height: 270px;
  }

  ${When.Large} {
    width: 300px;
  }

  ${When.LargeAndTall} {
    /* height: 500px; */
  }
`

const MainImage2 = styled(Img)`
  height: ${({ height }) => (height ? height : '300px')};
  transition: height 0.2s linear;

  background: #fff;
  margin: 0 20px;

  & img {
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-height: 600px) {
    height: 270px;
  }

  ${When.Large} {
    width: 500px;
  }

  ${When.LargeAndTall} {
    /* height: 500px; */
  }
`

const MoreBottlesContainer = styled.div`
  display: none;
  justify-content: flex-end;
  width: 400px;

  ${When.Large} {
    display: flex;
  }
`

const BottleContainerRightAlign = styled.div`
  display: flex;
  flex-direction: column;
`

const SizeSwitchingButtons = styled.div`
  display: flex;
  justify-content: center;
`

const StyledButtonToggle = styled(ButtonToggle)`
  display: flex;
  margin-right: 16px;
  &:last-child {
    margin: 0;
  }
`

const ColorIndicator = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-right: 10px;
`

const MoreBottlesTitle = styled.h3`
  /* margin-top: 24px; */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  margin: 0 0 18px 0;
`

const MoreBottlesGrid = styled.div`
  display: grid;
  grid-template-columns: 112px 112px;
  grid-row-gap: 19px;
  grid-column-gap: 31px;
  justify-items: center;
`

const SmallBottleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SmallBottle = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
  width: 112px;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  transition: border 0.2s linear;
  border: 1px solid #fff;
  margin-bottom: 4px;
  padding: 6px;
  ${({ active }) => active && `border: 1px solid ${grayOnWhite};`}

  &:hover {
    border: 1px solid ${grayOnWhite};
  }
`

const SmallBottleImg = styled(Img)`
  height: 100%;
  width: 100%;
`

const SmallBottleName = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 500ms ease 0s;
`

const MoreBottlesFixedHeightPart = styled.div`
  height: 344px;
  margin-bottom: 32px;
`

const MoreBottlesPageSwitcher = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PageIndicator = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`

const ContactOverlay = styled.div`
  transition: transform 500ms ease-in-out;
  transform: ${({ isVisible }) =>
    isVisible ? 'translateY(0)' : 'translateY(100%)'};
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`

const ContactCloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
`

const ContactBox = styled.div`
  width: 100%;
  background-color: #212121;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 11;
  margin: 0;

  ${When.Large} {
    max-width: 704px;
  }
`
const ContactUs = styled.div`
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 28px;
`
const H = styled.h3`
  font-size: 18px;
  line-height: 27px;
  color: white;
  margin: 0px;
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
`
const P = styled.p`
  font-size: 12px;
  color: white;
  margin: 0;
  margin-top: 10px;
  line-height: 18px;
  font-weight: 600;
  font-family: Poppins;
`
const NameField = styled.div`
  ${({ expandOnMobile }) =>
    `
${expandOnMobile ? When.Medium : When.Short} { {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  max-width: 544px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
`};
`

const Message = styled.div`
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
`

const Form = styled.form`
  max-width: 544px;
  width: 90%;
  & input,
  & textarea,
  & label {
    font-family: Poppins !important;
    font-style: normal;
    font-weight: normal;
  }
`

const StyledTextField = styled(TextField)`
  ${({ name }) =>
    name === 'phone'
      ? `& label:not(.MuiInputLabel-shrink) {
    margin-left: 55px;
  }
  & input {
    padding-left: 55px;
  }
  `
      : null};
  position: relative;
  ${({ expandOnMobile }) =>
    expandOnMobile
      ? `
    padding: 0px;
    margin: 0;
    max-width: 544px;
    width: 100%;
    font-family: Poppins;
    line-height: 21px;
    font-size: 14px;
    font-weight: 400;
    & textarea {
      color: #ffffff;
    }
}
${When.Medium} {
  padding: 0px;
  margin: 0;
  width: 100%;
  font-family: Poppins;
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 32px;
}
`
      : `
  padding: 0px;
  margin: 0;
  width: 100%;
  font-family: Poppins;
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 32px;
}
}
`};
`
const StyledTextField2 = styled(TextField)`
  padding: 0px;
  margin: 0;
  max-width: 544px;
  width: 100%;
  font-family: Poppins;
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
  & textarea {
    color: #ffffff;
  }
`

const BottleBox = styled.div`
  display: none;

  ${When.Large} {
    display: block;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
    max-width: 300px;
    width: 100%;
    position: absolute;
    bottom: 300px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
`

const SendBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 19px;
  align-items: center;
`

const Wrapper = styled.div`
  ${({ expandOnMobile, firstOfTwo }) =>
    `
  ${expandOnMobile ? When.Medium : When.Short} {
    max-width: 265px;
    width: 90%;
    margin-bototm: 10px;
  }

  ${When.Medium} {
    ${firstOfTwo && `margin-right: 24px;`}
  } 
`};
`
const Parent = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #212121;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  padding-bottom: 52px;

  ${When.Medium} {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #212121;
    position: absolute;
    bottom: 0;
    top: 0;
    /* transform: translateX(-50%); */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    padding-bottom: 52px;
  }

  ${When.Large} {
    top: unset;
    padding-top: 40px;
    padding-bottom: 52px;
    max-width: 704px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledSendButton = styled(ButtonSend)`
  margin-top: 15px;
`

const MobileFullWidthWrapper = styled.div`
  // position: fixed;
  display: flex;
  justify-content: center;
  background-color: ${white};
  width: 100%;
  bottom: 0;
  @media screen and (max-height: 650px) {
    // position: absolute;
    bottom: 0;
    // margin-bottom: -25px;
  }
  ${When.Large} {
    display: none;
  }
`

const MobileControls = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 240px;
  /* padding: 0 0 40px 0; */
  margin: 0;
  height: 40px;

  ${When.Large} {
    display: none;
  }
`

const MobileControlItem = styled.li`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  ${({ active }) => active === 'Y' && 'font-weight: 700'}
`

// Contact Success Styles

const ContactSuccessOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const PostMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  width: 90%;

  ${When.Large} {
    max-width: 350px;
    width: 100%;
  }
`
const Round = styled.div`
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  margin-bottom: 12px;
  background-color: #4dae50;

  display: flex;
  justify-content: center;
  align-items: center;
`

// Sent styles
const YourMessageWasSent = styled.h3`
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  color: #212121;

  ${When.Large} {
    font-size: 24px;
    margin-bottom: 4px;
  }
`
const WeWillGetInTouch = styled.p`
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  color: #212121;

  ${When.Large} {
    font-size: 14px;
    max-width: 279px;
    width: 90%;
    margin-bottom: 18px;
  }
`
const MessageSentSendBox = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
`

const BackToProductButton = styled(Button)`
  width: 100%;
`

const ErrorText = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #f44336;
  margin-top: 5px;
`

const CountryCodeSelector = styled.button`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #ffffff;
  width: 50px;
  height: 30px;
  margin-top: -30px;
  z-index: 1000;
  &:not(:focus) > div {
    display: none;
  }
  border: none;
  outline: none;
  text-align: left;
  padding: 3px;
  position: relative;
  cursor: pointer;
`
const CountryCodeInner = styled.div`
  top: 22px;
  height: 140px;
  margin-top: 6px;
  margin-left: -6px;
  width: 256px;
  background-color: #fff;
  z-index: 100;
  position: absolute;
  border-radius: 0px 0px 4px 4px;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
  }
`

const CountryCode = styled.div`
  display: flex;
  height: 28px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 49px;
  &:hover {
    background: #e0e0e0;
  }
  cursor: pointer;
`

const CountryCodeCountry = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  max-width: 140px;
  text-align: left;
`
const CountryCodeCode = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
`

const Preloader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* &::before {
    content: 'Loading ...';
  } */
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
`

const OverlayModal = styled.div`
  position: fixed;
  background: #212121f2;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  transition: transform 400ms ease-in-out;
  transition: visibility 400ms ease-in-out, background 400ms ease-in-out;
  ${({ isOpen }) =>
    isOpen
      ? ''
      : `
background: transparent;
visibility: hidden;
z-index: 999;
`}
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContents = styled.div`
  & * {
    transition: visibility 400ms ease-in-out, background 400ms ease-in-out;
  }
  ${({ isOpen }) =>
    isOpen
      ? `
& * {
  visibility: visible
}
`
      : `
& * {
  visibility: hidden
}
`}
`

const ModalCloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
`

const FormContainer = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${When.Large} {
    height: max-content;
    max-width: 1124px;
  }

  & h2:first-of-type {
    color: #fff;
  }
`

const SpaceFiller = styled.div`
  height: 70px;
`

const SHOW_NUM_MORE = 4

export default function Products({ data, location }) {
  const {
    id,
    name,
    measurement,
    height,
    weight,
    diameter,
    colors,
    bottle_variants,
    product_category,
  } = data.bottle.edges[0].node

  const [isWindow, setIsWindow] = React.useState(false)

  React.useEffect(() => {
    setIsWindow(true)

    return () => setIsWindow(false)
  }, [])

  // take only bottles that have variants
  const moreBottlesFiltered = data.moreBottles.edges.filter(
    ({ node }) => node.bottle_variants.length
  )

  const [modalOpen, setModalOpen] = useState(false)

  const moreBottles = useMemo(
    () =>
      moreBottlesFiltered
        .reduce(
          (prev, { node }) => [
            ...prev,
            ...node.bottle_variants.map((v, index) => ({
              ...node,
              id: `${node.id}-${index}`,
              fluid: v.photo?.localFile?.childImageSharp.fluid,
            })),
          ],
          []
        )
        .map(e => ({ node: e })),
    [moreBottlesFiltered]
  )

  const { large } = useMediaMatch()

  const [mobileView, setMobileView] = useState<'bottle' | 'specs'>('bottle')
  const [showContactOverlay, setShowContactOverlay] = useState<boolean>(false)

  const [currentVariant, setCurrentVariant] = useQueryParam('type', NumberParam)
  const [currentPage, setCurrentPage] = useState(0)

  const numberOfPages = useMemo(
    () => Math.ceil(moreBottles.length / SHOW_NUM_MORE),
    [moreBottles]
  )

  const hasPrevPage = currentPage > 0
  const hasNextPage = currentPage < numberOfPages - 1

  const handlePrevPage = useCallback(() => {
    if (hasPrevPage) {
      setCurrentPage(currentPage - 1)
    }
  }, [currentPage, hasNextPage])

  const handleNextPage = useCallback(() => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1)
    }
  }, [currentPage, hasNextPage])

  useEffect(() => {
    const itemIndex = moreBottles.findIndex(({ node }) => node.id.includes(id))

    setCurrentPage(Math.floor(itemIndex / SHOW_NUM_MORE))
  }, [id, data])

  useEffect(() => {
    if (showContactOverlay) {
      const listener = e => {
        if (e.key === 'Escape') {
          setShowContactOverlay(false)
        }
      }
      document.addEventListener('keyup', listener)

      return () => {
        document.removeEventListener('keyup', listener)
      }
    }
  }, [setShowContactOverlay, showContactOverlay])

  const attachmentLink = useMemo(
    () => bottle_variants[currentVariant]?.attachment[0]?.localFile?.publicURL,
    [currentVariant]
  )

  const nextBottle = useMemo(() => {
    const index = moreBottles.findIndex(
      e => e.node.id === `${id}-${currentVariant}`
    )
    return moreBottles[index + 1]?.node
  }, [id, currentVariant])

  const previousBottle = useMemo(() => {
    const index = moreBottles.findIndex(
      e => e.node.id === `${id}-${currentVariant}`
    )
    return moreBottles[index - 1]?.node
  }, [id, currentVariant])

  const slideToNextPage = () => {
    if (nextBottle) {
      navigate(
        `/${nextBottle.fields.categorySlug}/bottles/${
          nextBottle.fields.slug
        }?type=${nextBottle.id.split('-')[1]}`
      )
    }
  }

  const slideToPreviousPage = () => {
    if (previousBottle) {
      navigate(
        `/${previousBottle.fields.categorySlug}/bottles/${
          previousBottle.fields.slug
        }?type=${previousBottle.id.split('-')[1]}`
      )
    }
  }

  const handleSliderItemClick = () => {
    setCurrentVariant(previous => previous)
    setModalOpen(true)
  }

  const [isMainImageLoading, setIsMainImageLoading] = useState(true);

  return (
    <>
      <OverlayModal
        id="overlay-modal"
        isOpen={modalOpen}
        onClickCapture={e => {
          e.persist()
          if (e.target.id === 'overlay-modal') {
            setModalOpen(false)
          }
        }}
      >
        <ModalContents isOpen={modalOpen}>
          <ModalCloseButton onClick={() => setModalOpen(false)}>
            <Cross stroke={white} />
          </ModalCloseButton>
          <StyledHorizontalSider2
            horizontalAlign="top"
            itemListContainerStyles={css`
              width: 100%;
            `}
            keys={v => v.id}
            showControls={true}
            items={bottle_variants}
            itemTemplate={(variant: any) => (
              // <motion.div
              //   animate={{ opacity: 1, y: 0 }}
              //   initial={{ opacity: 0, y: -20 }}
              //   transition={{ duration: 0.3 }}
              // >
              <MainImage2
                loading="eager"
                height={'80vh'}
                fluid={variant.photo.localFile?.childImageSharp.fluid}
                alt={variant.name}
                imgStyle={{ objectFit: 'contain' }}
              />
              // </motion.div>
            )}
            onChangePage={pageNum => setCurrentVariant(pageNum)}
            pageSize={1}
            slideBy="page"
            onItemClick={handleSliderItemClick}
            page={currentVariant}
          ></StyledHorizontalSider2>
        </ModalContents>
      </OverlayModal>
      <Layout
        location={location}
        title={`${product_category && product_category.name} Bottle - ${name}`}
        style={{ minHeight: '480px' }}
      >
        <CenterEvrytingHorizontally>
          <Container>
            <ContainerOfTheThree>
              {/* When flex-direction gets reversed on mobile views this goes at the bottom */}
              <MobileFullWidthWrapper>
                <MobileControls>
                  <MobileControlItem
                    active={mobileView === 'bottle' ? 'Y' : 'N'}
                    onClick={() => setMobileView('bottle')}
                  >
                    Bottle
                  </MobileControlItem>
                  <MobileControlItem
                    active={mobileView === 'specs' ? 'Y' : 'N'}
                    onClick={() => setMobileView('specs')}
                  >
                    Specs
                  </MobileControlItem>
                  <MobileControlItem>
                    <DownloadLink
                      disabled={!attachmentLink}
                      href={attachmentLink || 'javascript:void(0)'}
                      download={name}
                      target="_blank"
                      style={{ fontWeight: 400 }}
                    >
                      Print
                    </DownloadLink>
                  </MobileControlItem>
                  <MobileControlItem
                    onClick={() => setShowContactOverlay(true)}
                  >
                    Contacts
                  </MobileControlItem>
                </MobileControls>
              </MobileFullWidthWrapper>
              <SpecsContainer
                style={{
                  display: mobileView === 'specs' || large ? 'flex' : 'none',
                }}
              >
                <Name>{name}</Name>
                <SpecsTable>
                  <SpecsCellIcon>
                    <Height stroke={grayOnBlack} />
                  </SpecsCellIcon>
                  <SpecsCell>Height</SpecsCell>
                  <SpecsCell>
                    <SpecValueText>{height} mm</SpecValueText>
                  </SpecsCell>
                  <SpecsCellIcon>
                    <Weight stroke={grayOnBlack} />
                  </SpecsCellIcon>
                  <SpecsCell>Weight</SpecsCell>
                  <SpecsCell>
                    <SpecValueText>{weight} g</SpecValueText>
                  </SpecsCell>
                  <SpecsCellIcon>
                    <FillingCAP stroke={grayOnBlack} />
                  </SpecsCellIcon>
                  <SpecsCell>Filling CAP</SpecsCell>
                  <SpecsCell>
                    <SpecValueText>{measurement} ml</SpecValueText>
                  </SpecsCell>
                  <SpecsCellIcon>
                    <Color fill={grayOnBlack} stroke={grayOnBlack} />
                  </SpecsCellIcon>
                  <SpecsCell>Colors</SpecsCell>
                  <SpecsCell>
                    <SpecValueText>{colors}</SpecValueText>
                  </SpecsCell>
                  <SpecsCellIcon>
                    <Diameter stroke={grayOnBlack} />
                  </SpecsCellIcon>
                  <SpecsCell>Diameter</SpecsCell>
                  <SpecsCell>
                    <SpecValueText>{diameter} mm</SpecValueText>
                  </SpecsCell>
                </SpecsTable>
                <ContactAndDownloadContainer>
                  <ContactUsButton onClick={() => setShowContactOverlay(true)}>
                    Contact Us
                  </ContactUsButton>
                  <DownloadLink
                    disabled={!attachmentLink}
                    href={attachmentLink || 'javascript:void(0)'}
                    download={name}
                    target="_blank"
                  >
                    Download Print
                  </DownloadLink>
                </ContactAndDownloadContainer>
              </SpecsContainer>
              {isWindow ? (
                <BottleContainer>
                  {isMainImageLoading && 
                    <Preloader>
                    <img src={loadingGif} alt="Loading ..." />
                    </Preloader>
                  }
                    <StyledHorizontalSider
                    horizontalAlign="top"
                    height={!large && mobileView === 'specs' && '100px'}
                    itemListContainerStyles={css`
                      width: 100%;
                    `}
                    keys={v => v.id}
                    showControls={true}
                    items={bottle_variants}
                    itemTemplate={(variant: any) => (
                      // <motion.div
                      //   animate={{ opacity: 1, y: 0 }}
                      //   initial={{ opacity: 0, y: -20 }}
                      //   transition={{ duration: 0.3 }}
                      // >
                      <MainImage
                        loading="eager"
                        height={!large && mobileView === 'specs' && '100px'}
                        fluid={variant.photo.localFile?.childImageSharp.fluid}
                        alt={variant.name}
                        imgStyle={{ objectFit: 'contain' }}
                        onLoad={() => setIsMainImageLoading(false)}
                        onError={() => setIsMainImageLoading(false)}
                      />
                      // </motion.div>
                    )}
                    onChangePage={pageNum => setCurrentVariant(pageNum)}
                    enableLeftArrow={previousBottle}
                    enableRightArrow={nextBottle}
                    slideLeftFail={slideToPreviousPage}
                    slideRightFail={slideToNextPage}
                    pageSize={1}
                    slideBy="page"
                    onItemClick={handleSliderItemClick}
                    page={currentVariant}
                  />
                  
                  <NameMobile>{name}</NameMobile>
                  <SizeSwitchingButtons>
                    {bottle_variants.map((variant, index) => (
                      <StyledButtonToggle
                        onClick={() => setCurrentVariant(index)}
                        active={
                          variant.colorName ===
                          bottle_variants[currentVariant]?.colorName
                        }
                      >
                        <ColorIndicator color={variant.color} />
                        <div>{variant.colorName}</div>
                      </StyledButtonToggle>
                    ))}
                  </SizeSwitchingButtons>
                </BottleContainer>
              ) : null}
              <MoreBottlesContainer>
                <BottleContainerRightAlign>
                  <MoreBottlesFixedHeightPart>
                    <MoreBottlesTitle>
                      More {product_category && product_category.name} bottles
                    </MoreBottlesTitle>

                    {/* Start loading more bottles only after the main image has finshed loading */}
                    {!isMainImageLoading && 
                    <MoreBottlesGrid>
                      {moreBottles
                        .slice(
                          currentPage * SHOW_NUM_MORE,
                          Math.min(
                            currentPage * SHOW_NUM_MORE + SHOW_NUM_MORE,
                            moreBottles.length
                          )
                        )
                        .map(({ node }) => (
                          <SmallBottleContainer>
                            <SmallBottle
                              key={node.id}
                              to={`/${node.fields.categorySlug}/bottles/${
                                node.fields.slug
                              }?type=${node.id.split('-')[1]}`}
                              active={node.id === id}
                            >
                              <SmallBottleImg
                                fluid={node?.fluid}
                                onLoad={() =>
                                  (document.getElementById(
                                    `${node.id}-name`
                                  ).style.opacity = '1')
                                }
                                imgStyle={{ objectFit: 'contain' }}
                              />
                            </SmallBottle>
                            <SmallBottleName
                              style={{ opacity: 0 }}
                              id={`${node.id}-name`}
                            >
                              {node.name}
                            </SmallBottleName>
                          </SmallBottleContainer>
                        ))}
                    </MoreBottlesGrid>
                  }
                  </MoreBottlesFixedHeightPart>
                  <MoreBottlesPageSwitcher>
                    <ButtonIcon
                      icon={<ArrowLeft stroke={black} forButton={true} />}
                      colors="light"
                      stroke={false}
                      onClick={() => handlePrevPage()}
                      disabled={currentPage === 0}
                    ></ButtonIcon>
                    <PageIndicator>
                      {currentPage + 1} | {numberOfPages}
                    </PageIndicator>
                    <ButtonIcon
                      icon={<ArrowRight stroke={black} forButton={true} />}
                      colors="light"
                      stroke={false}
                      disabled={currentPage + 1 === numberOfPages}
                      onClick={() => handleNextPage()}
                    ></ButtonIcon>
                  </MoreBottlesPageSwitcher>
                </BottleContainerRightAlign>
              </MoreBottlesContainer>
            </ContainerOfTheThree>
          </Container>
        </CenterEvrytingHorizontally>
      </Layout>
      <ContactOverlay isVisible={showContactOverlay}>
        <ContactCloseButton onClick={() => setShowContactOverlay(false)}>
          <Cross stroke={white} />
        </ContactCloseButton>
        <FormContainer>
          <SpaceFiller />
          <ContactForm
            emailType="about-bottle"
            additionalData={{
              bottle: (() => {
                try {
                  return `${
                    location.href.split('/').pop().split('?type=')[0]
                  } ${bottle_variants[currentVariant]?.colorName}`
                } catch (error) {
                  return ''
                }
              })(),
            }}
          />
        </FormContainer>
      </ContactOverlay>
    </>
  )
}

export const query = graphql`
  query BottleQuery($strapiId: String!, $productCategoryStrapiId: String!) {
    bottle: allStrapiBottle(
      filter: { strapiId: { eq: $strapiId } }
      sort: { order: ASC, fields: order }
    ) {
      edges {
        node {
          id
          name
          measurement
          height
          weight
          diameter
          colors
          product_category {
            name
          }
          bottle_variants {
            id
            colorName
            color
            photo {
              localFile {
                childImageSharp {
                  fluid(
                    extractLeft: 0
                    extractTop: 200
                    extractWidth: 3000
                    extractHeight: 5800
                    quality: 40
                    trim: 1.5
                  ) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            attachment {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }

    moreBottles: allStrapiBottle(
      filter: { product_category: { id: { eq: $productCategoryStrapiId } } }
      sort: { order: ASC, fields: order }
    ) {
      edges {
        node {
          id
          name
          measurement
          height
          weight
          diameter
          fields {
            categorySlug
            slug
          }
          bottle_variants {
            colorName
            photo {
              localFile {
                childImageSharp {
                  fluid(
                    extractLeft: 0
                    extractTop: 200
                    extractWidth: 3000
                    extractHeight: 5800
                    quality: 100
                    trim: 1.5
                    maxHeight: 224
                  ) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
